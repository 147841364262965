<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    @click:outside="goBack"
  >
    <v-card>
      <v-card-title>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-switch
              v-on="on"
              v-model="showPointTotals"
              class="mt-0 pt-0"
              hide-details
              @change="setUserStoryText"
            />
          </template>
          <span>{{ $t('general.controls.show_total_points') }}</span>
        </v-tooltip>
        <div>
          {{ title }}
        </div>
      </v-card-title>

      <v-card-text>
        <v-textarea v-model="userStoryText" :label="$t('projects.general.task_list')" auto-grow />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click.native="goBack">
          {{ $t('general.controls.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'ProjectSprintUserStoriesSummary',

  data() {
    return {
      sprint: {},
      userStories: [],
      userStoryText: '',
      showPointTotals: false,
    };
  },

  computed: {
    ...mapState('projectSprints', ['projectSprints']),
    ...mapState('projectUserStories', ['projectUserStoriesBySprint']),
    ...mapGetters('projectUserStories', ['projectUserStoryPointTypes']),

    title() {
      return this.sprint.id
        ? `${this.$t('projects.general.sprint_what')} ${this.sprint.sprint_name} ${this.$t(
            'projects.general.requirements'
          )}`
        : this.$t('projects.general.unassigned_stories');
    },
  },

  created() {
    const sprintId = +this.$route.params.sprintId || null;
    this.sprint = this.projectSprints.find((s) => s.id === sprintId) || {};

    if (this.projectUserStoriesBySprint[sprintId]) {
      this.userStories = this.projectUserStoriesBySprint[sprintId];
      this.setUserStoryText();
    } else {
      this.$router.replace({ name: 'project' });
    }
  },

  methods: {
    goBack() {
      this.$router.push({ name: 'project' });
    },

    setUserStoryTextWithPointValues() {
      let userStoryText = '';
      for (let i = 0; i < this.userStories.length; i++) {
        const userStory = this.userStories[i];

        userStoryText += `${userStory.title}`;
        if (userStory.title[userStory.title.length - 1] !== '.') {
          userStoryText += '. ';
        } else {
          userStoryText += ' ';
        }

        for (let j = 0; j < this.projectUserStoryPointTypes.length; j++) {
          const points = this.projectUserStoryPointTypes[j];
          if (userStory[points.estimatedPointsFieldKey]) {
            userStoryText += `${points.prefix}${userStory[points.estimatedPointsFieldKey]} `;
          }
        }

        userStoryText += '\n';
      }
      this.userStoryText = userStoryText;
    },

    setUserStoryTextWithPointTotals() {
      let pointTotal;
      let userStoryText = '';

      for (let i = 0; i < this.userStories.length; i++) {
        const userStory = this.userStories[i];
        pointTotal = 0;
        userStoryText += `${userStory.title}`;

        for (let j = 0; j < this.projectUserStoryPointTypes.length; j++) {
          const points = this.projectUserStoryPointTypes[j];
          if (userStory[points.estimatedPointsFieldKey]) {
            pointTotal += +userStory[points.estimatedPointsFieldKey];
          }
        }

        if (userStory.title[userStory.title.length - 1] !== '.') {
          userStoryText += '.';
        }
        userStoryText += ` ${this.$t('general.total_short')}: ${pointTotal}.\n`;
      }

      this.userStoryText = userStoryText;
    },

    setUserStoryText() {
      if (this.showPointTotals) {
        this.setUserStoryTextWithPointTotals();
      } else {
        this.setUserStoryTextWithPointValues();
      }
    },
  },
};
</script>

<style scoped></style>
